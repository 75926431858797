function clear_check(a) {
    if (document.getElementById(a + '_result_image')) {
        document.getElementById(a + '_result_image').src = '/images/sp.gif';
    }
    $('#' + a + '_err').css('color', '#999999');
    return;
}

function check_phone_small(a) {
    var code = 'phone_code';
    var num = 'phone_num';
    var base = 'phone';
    if (a) {
        code = code + '_' + a;
        num = num + '_' + a;
        base = base + '_' + a;
    }
    only_digit(code);
    only_digit(num);

    $('#' + num + '_err').html(cursor_here);


    if (document.getElementById(code).value.length != 3 || document.getElementById(num).value.length != 7) {
        $('#' + code + '_err').css('color', 'red');
        document.getElementById(base + '_result_image').src = '/images/exclamation_red.png';
    }
    else {
        $('#' + code + '_err').css('color', '');
        document.getElementById(base + '_result_image').src = '/images/complete1.png';
    }


    prepare_field(num, '�����');
    prepare_field(code, '���');
}

function check_phone2(a) {

}

function check_phone(a) {
    var code = 'phone_code';
    var num = 'phone_num';
    var base = 'phone';
    if (a) {
        code = code + '_' + a;
        num = num + '_' + a;
        base = base + '_' + a;
    }
    only_digit(code);
    only_digit(num);

    $('#' + num + '_err').html(cursor_here);

    if (cursor_here != code && cursor_here != num) {
        if (document.getElementById(code).value.length != 3 || document.getElementById(num).value.length != 7) {
            $('#' + code + '_err').css('color', 'red');
            document.getElementById(base + '_result_image').src = '/images/exclamation_red.png';
        }
        else {
            $('#' + code + '_err').css('color', '');
            document.getElementById(base + '_result_image').src = '/images/complete1.png';
        }
    }
    if (cursor_here == num) {
        if (document.getElementById(code).value.length != 3) {
            $('#' + code + '_err').css('color', 'red');
            document.getElementById(base + '_result_image').src = '/images/exclamation_red.png';
        }
    }
    if (cursor_here == code) {
        if (document.getElementById(num).value.length != 7) {
            $('#' + code + '_err').css('color', 'red');
            document.getElementById(base + '_result_image').src = '/images/exclamation_red.png';
        }
    }
    prepare_field(num, '�����');
    prepare_field(code, '���');
}

function first_up(a) {
    if (document.getElementById(a).value) {
        var pos = getCaretPos(document.getElementById(a));

        var first = document.getElementById(a).value.substr(0, 1);
        first = first.toLocaleUpperCase(first);
        var last = document.getElementById(a).value.substr(1, document.getElementById(a).value.length);
        last = last.toLocaleLowerCase(last);
        if (document.getElementById(a).value != first + last) {
            document.getElementById(a).value = first + last;
        }
        setCaretPos(document.getElementById(a), pos);
    }
    return;
}

function only_digit(a) {
    if (document.getElementById(a).value) {
        var pos = getCaretPos(document.getElementById(a));
        var res = '';
        for (i = 0; i < document.getElementById(a).value.length; i++) {
            var ch = document.getElementById(a).value.substr(i, 1);
            if (ch == 0 || ch == 1 || ch == 2 || ch == 3 || ch == 4 || ch == 5 || ch == 6 || ch == 7 || ch == 8 || ch == 9) {
                res = res + ch;
            }
        }
        if (document.getElementById(a).value != res) {
            document.getElementById(a).value = res;
        }
        setCaretPos(document.getElementById(a), pos);
    }
    return;
}

function only_digit2(a) {

    if (document.getElementById(a).value) {

        var res = '';
        for (i = 0; i < document.getElementById(a).value.length; i++) {
            var ch = document.getElementById(a).value.substr(i, 1);
            if (ch == ',') {
                ch = '.';
            }
            if (ch == 0 || ch == 1 || ch == 2 || ch == 3 || ch == 4 || ch == 5 || ch == 6 || ch == 7 || ch == 8 || ch == 9 || ch == '.') {
                res = res + ch;
            }
        }

        if (document.getElementById(a).value != res) {
            document.getElementById(a).value = res;
        }

    }
    return;
}

function only_digit_str(a) {

    var res = '';
    for (i = 0; i < a.length; i++) {
        var ch = a.substr(i, 1);

        if (ch == ',') {
            ch = '.';
        }
        if (ch == 0 || ch == 1 || ch == 2 || ch == 3 || ch == 4 || ch == 5 || ch == 6 || ch == 7 || ch == 8 || ch == 9 || ch == '.') {
            res = res + ch;
        }
    }


    return res;
}

function only_letter(a) {
    if (document.getElementById(a).value) {
        var pos = getCaretPos(document.getElementById(a));
        setCaretPos(document.getElementById(a), pos);
        var res = '';
        res = document.getElementById(a).value.replace(/[^a-z�-�]/gi, "");
        if (document.getElementById(a).value != res) {
            document.getElementById(a).value = res;
        }
        setCaretPos(document.getElementById(a), pos);
    }

    return;
}


function check_field(field_id, no_check_child, a) {

    if (a == 1) {
        last_check = 1;
    } else {
        last_check = 0;
    }
    if (!check_list[field_id]) {
        return true;
    }
    if (!check_list[field_id]['des']) {
        field_des = field_id + '_des';
    } else {
        field_des = check_list[field_id]['des'];
    }
    if (!check_list[field_id]['err']) {
        field_err = field_id + '_err';
    } else {
        field_err = check_list[field_id]['err'];
    }
    if (!check_list[field_id]['result_image']) {
        result_image = field_id + '_result_image';
    } else {
        result_image = check_list[field_id]['result_image'];
    }

    if (check_list[field_id]['error']) {
        error_mes = check_list[field_id]['error'];
    } else {
        error_mes = '';
    }
    if (check_list[field_id]['empty_err']) {
        error_mes = ' ';
    }

    len = check_list[field_id]['len'];
    func = check_list[field_id]['func'];
    len_min = check_list[field_id]['len_min'];
    len_max = check_list[field_id]['len_max'];
    child = check_list[field_id]['child'];
    field_def = check_list[field_id]['def'];
    field_ajax_image = field_id + '_ajax_image';
    $('#' + field_des).css('color', '');
    $('#' + field_err).html(error_mes);
    $('#' + field_err).css('color', '#999999');
    if (!document.getElementById(field_id).value || document.getElementById(field_id).value == field_def) {

//		$('#'+field_des).css('color', 'red');
        if (field_def) {
            document.getElementById(field_id).value = field_def;
            $('#' + field_id).css('color', '#999999');
        }
        if (document.getElementById(result_image)) {
            document.getElementById(result_image).src = '/images/exclamation_red.png';
        }
        return false;
    }


    if (len > 0 && document.getElementById(field_id).value.length != len) {
        if (check_list[field_id]['no_text_error'] != 1) {
            $('#' + field_err).css('color', 'red');
            if (error_mes) {
                $('#' + field_err).html(error_mes);
            } else {
                $('#' + field_err).html('' + len + ' ������');
            }
        }
        if (check_list[field_id]['no_image_error'] != 1) {
            if (document.getElementById(result_image)) {
                document.getElementById(result_image).src = '/images/exclamation_red.png';
            }
        }
        return false;
    }

    if (len_min > 0 || len_max > 0) {
        var len_err = '';
        if (len_min > 0 && document.getElementById(field_id).value.length < len_min) {
            len_err = len_err + '�� ' + len_min + ' ������';
        }
        if (len_max > 0 && document.getElementById(field_id).value.length > len_max) {
            len_err = len_err + '�� ' + len_max + ' ������';
        }
        if (len_err != '') {
            if (check_list[field_id]['no_text_error'] != 1) {
                $('#' + field_err).css('color', 'red');
                if (error_mes) {
                    $('#' + field_err).html(error_mes);
                } else {
                    $('#' + field_err).html(len_err);
                }
            }
            if (check_list[field_id]['no_image_error'] != 1) {
                if (document.getElementById(result_image)) {
                    document.getElementById(result_image).src = '/images/exclamation_red.png';
                }
            }
            return false;
        }
    }

    if (func) {
        if (document.getElementById(result_image)) {
            document.getElementById(result_image).src = '/images/reg_indicator_blue.gif';
        }
        var result = eval(func);

        if (last_check == 1 && result.error == 2) {
            result.error = 1;
        }
        if (result.error == 1) {
            if (check_list[field_id]['no_text_error'] != 100) {
                $('#' + field_err).css('color', 'red');
                len_err = result.error_mes;
                $('#' + field_err).html(len_err);
            }
            if (check_list[field_id]['no_image_error'] != 100) {
                if (document.getElementById(result_image)) {
                    document.getElementById(result_image).src = '/images/exclamation_red.png';
                }
            }
            return false;
        }

        if (result.error == 2) {
            if (document.getElementById(result_image)) {
                document.getElementById(result_image).src = '/images/sp.gif';
            }
            return false;
        }

        if (result.error == 0) {
            if (check_list[field_id]['no_image_error'] != 100) {
                if (document.getElementById(result_image)) {
                    document.getElementById(result_image).src = '/images/complete1.png';
                }
            }
            return true;
        }
    }

    err_child = 0;
    if (!no_check_child && child) {
        for (i = 0; i < child.length; i++) {
            if (!check_field(child[i], 1, a)) {
                err_child = 1;
            }
        }
    }
    if (err_child == 1) {
//		$('#'+field_des).css('color', 'red');
        if (check_list[field_id]['no_text_error'] != 1) {
            $('#' + field_err).css('color', 'red');
        }
        if (check_list[field_id]['no_image_error'] != 1) {
            if (document.getElementById(result_image)) {
                document.getElementById(result_image).src = '/images/exclamation_red.png';
            }
        }
        return false;
    }

    $('#' + field_err).css('color', '#999999');
    $('#' + field_des).css('color', '');
    $('#' + field_err).html(error_mes);
    if (check_list[field_id]['no_image_error'] != 1) {
        if (document.getElementById(result_image)) {
            document.getElementById(result_image).src = '/images/complete1.png';
        }
    }
    return true;
}

var check_login = function () {
    var send_data = 'what=check_login&login=' + document.getElementById('login').value;
    result = new Object;
    var res = $.ajax({
        url: '/ajax_page.php',
        data: send_data,
        dataType: 'text',
        cache: false,
        async: false
    }).responseText;
    if (res > 0) {
        result.error = 1;
        result.error_mes = '�����';
    } else {
        result.error = 0;
    }
    return result;
}

var check_login = function () {
    var send_data = 'what=check_login&login=' + document.getElementById('login').value;
    result = new Object;
    var res = $.ajax({
        url: '/ajax_page.php',
        data: send_data,
        dataType: 'text',
        cache: false,
        async: false
    }).responseText;
    if (res > 0) {
        result.error = 1;
        result.error_mes = '�����';
    } else {
        result.error = 0;
    }
    return result;
}

var check_old_pass = function () {
    var send_data = 'what=check_old_pass&pass=' + document.getElementById('old_pass').value + '&user_id=' + user_id;
    result = new Object;
    var res = $.ajax({
        url: '/ajax_page.php',
        data: send_data,
        dataType: 'text',
        cache: false,
        async: false
    }).responseText;
    if (res == 0) {
        result.error = 1;
        result.error_mes = '�������� ������';
    } else {
        result.error = 0;
    }
    return result;
}

function check_mail(mail, aaa) {
    result = new Object;
    if (document.getElementById(mail).value.length < 6) {
        result.error = 1;
        result.error_mes = '�������� �����';
    }
    else {
        var send_data = 'what=check_mail&mail=' + document.getElementById(mail).value;

        var res = $.ajax({
            url: '/ajax_page.php',
            data: send_data,
            dataType: 'text',
            cache: false,
            async: false
        }).responseText;

        if (res == 2) {
            result.error = 1;
            result.error_mes = '�������� �����';
        }
        if (aaa != 1) {
            if (res == 1) {
                result.error = 1;
                result.error_mes = '�����';
            }
        }

        if (res == 0) {
            result.error = 0;
        }
    }

    return result;
}

var check_pass2 = function () {
    result = new Object;
    result.error = 2;
    if (!document.getElementById('pass').value) {
        result.error = 1;
        result.error_mes = '������ ����';
        return result;
    }
    if (!document.getElementById('pass2').value) {
        result.error = 1;
        return result;
    }

    if (document.getElementById('pass2').value == document.getElementById('pass').value) {
        result.error = 0;
        return result;
    }
    else {
        var check_str = document.getElementById('pass').value.substr(0, document.getElementById('pass2').value.length);

        if (check_str != document.getElementById('pass2').value) {
            result.error = 1;
            result.error_mes = '������������';
            return result;
        }
        if (document.getElementById('pass2').value.length < document.getElementById('pass').value.length) {
            result.error = 2;
            return result;
        }
    }
    return result;
}

function set_default_value(field_id, dval) {
    if (document.getElementById(field_id).value == '') {
        document.getElementById(field_id).value = dval;
        $('#' + field_id).css('color', '#999999');
    }
}

function prepare_field(field_id, dval) {
    if (document.getElementById(field_id).value == dval) {
        document.getElementById(field_id).value = '';
        $('#' + field_id).css('color', '#000000');
    }
    else {
        $('#' + field_id).css('color', '#000000');
    }
}

function setCaretPos(obj, pos) {
    if (obj.createTextRange) {
        // IE
        var range = obj.createTextRange();
        range.collapse(true);
        range.moveEnd('character', pos);
        range.moveStart('character', pos);
        range.select();
    }
    else if (obj.selectionEnd) {
        // Gecko
        obj.selectionEnd = pos;
    }

    return;
}

function getCaretPos(obj) {
    obj.focus();

    if (obj.selectionStart) return obj.selectionStart;//Gecko
    else if (document.selection)//IE
    {
        var selection = document.selection.createRange().getBookmark();
        var selection2 = obj.createTextRange();
        selection2.moveToBookmark(selection);
        var before = obj.createTextRange();
        before.setEndPoint('EndToStart', selection2);
        return before.text.length;

        //return sel.offsetLeft;
    }

    return 0;
}

function check_all_fields(a) {

    if (!a || a < 1 || a > 6) {
        a = 1;
    }
    allparam = new Object;

    if (a == 1) {
        only_digit2('first_vol');
//		if (document.getElementById('first_vol').value=='') {document.getElementById('first_vol').value='100';}
        only_digit2('first_mosh');
//		if (document.getElementById('first_mosh').value=='') {document.getElementById('first_mosh').value='2000';}
        only_digit2('first_cool');
//		if (document.getElementById('first_cool').value=='') {document.getElementById('first_cool').value='10';}
        only_digit2('first_hot');
//		if (document.getElementById('first_hot').value=='') {document.getElementById('first_hot').value='55';}

        var tmp;
        var vol = document.getElementById('first_vol').value;
        var mosh = document.getElementById('first_mosh').value;
        var cool = document.getElementById('first_cool').value;
        var hot = document.getElementById('first_hot').value;
        cool = Math.abs(cool);
        hot = Math.abs(hot);

        allparam.vol = vol;
        allparam.mosh = mosh;

        //	if (cool>hot) {tmp=hot;hot=cool;cool=tmp;}

        //	document.getElementById('first_cool').value=cool;
        //	document.getElementById('first_hot').value=hot;

        var res = $.ajax({
            url: '/ajax_page.php',
            type: 'post',
            data: {what: 'calculator_first', vol: vol, mosh: mosh, cool: cool, hot: hot},
            dataType: 'html',
            complete: function (data) {
                setTimeout("get_boiler(1);", 500);
            },
            cache: false,
            async: false
        }).responseText;


        if (res == '') {
            res = '<span class="err-data">��������� ������</span>';
        }
        $('#first_text').html(res);
    }

    if (a == 2) {
        only_digit2('second_vol');
//	if (document.getElementById('second_vol').value=='') {document.getElementById('second_vol').value='100';}
        only_digit2('second_cool');
//	if (document.getElementById('second_cool').value=='') {document.getElementById('second_cool').value='10';}
        only_digit2('second_hot');
//	if (document.getElementById('second_hot').value=='') {document.getElementById('second_hot').value='55';}
        only_digit2('second_hc');
//	if (document.getElementById('second_hc').value=='') {document.getElementById('second_hc').value='4';}

        var tmp;
        var vol = document.getElementById('second_vol').value;
        var hc = document.getElementById('second_hc').value;
        var cool = document.getElementById('second_cool').value;
        var hot = document.getElementById('second_hot').value;
        cool = Math.abs(cool);
        hot = Math.abs(hot);
        hc = Math.abs(hc);

//		if (cool>hot) {tmp=hot;hot=cool;cool=tmp;}

//		document.getElementById('second_cool').value=cool;
//		document.getElementById('second_hot').value=hot;	
//		if (hc>hot || hc<cool) {hc=(hot+cool)/2;hc=Math.abs(hc);}
//		document.getElementById('second_hc').value=hc;

        allparam.vol = vol;
        var res = $.ajax({
            url: '/ajax_page.php',
            type: 'post',
            data: {what: 'calculator_second', vol: vol, hc: hc, cool: cool, hot: hot},
            dataType: 'html',
            complete: function (data) {
                setTimeout("get_boiler(2);", 500);
            },
            cache: false,
            async: false
        }).responseText;


        if (res == '') {
            res = '<span class="err-data">���������  ������</span>';
        }
        $('#second_text').html(res);
    }
    if (a == 3) {
        only_digit2('third_vol');
//		if (document.getElementById('third_vol').value=='') {document.getElementById('third_vol').value='100';}
        only_digit2('third_cool');
//		if (document.getElementById('third_cool').value=='') {document.getElementById('third_cool').value='10';}
        only_digit2('third_hot');
//		if (document.getElementById('third_hot').value=='') {document.getElementById('third_hot').value='55';}
        only_digit2('third_price');
//		if (document.getElementById('third_price').value=='') {document.getElementById('third_price').value='4';}

        var vol = document.getElementById('third_vol').value;
        var hot = document.getElementById('third_hot').value;
        var cool = document.getElementById('third_cool').value;
        var price = document.getElementById('third_price').value;
        allparam.vol = vol;
        var res = $.ajax({
            url: '/ajax_page.php',
            type: 'post',
            data: {what: 'calculator_third', vol: vol, price: price, cool: cool, hot: hot},
            dataType: 'html',
            complete: function (data) {
                setTimeout("get_boiler(3);", 500);
            },
            cache: false,
            async: false
        }).responseText;

        if (res == '') {
            res = '<span class="err-data">���������  ������</span>';
        }
        $('#third_text').html(res);

    }


    if (a == 4) {
        only_digit2('for_mosh');
//		if (document.getElementById('third_vol').value=='') {document.getElementById('third_vol').value='100';}
        only_digit2('for_cool');
//		if (document.getElementById('third_cool').value=='') {document.getElementById('third_cool').value='10';}
        only_digit2('for_hot');
//		if (document.getElementById('third_hot').value=='') {document.getElementById('third_hot').value='55';}


        var mosh = document.getElementById('for_mosh').value;
        var hot = document.getElementById('for_hot').value;
        var cool = document.getElementById('for_cool').value;

        allparam.mosh = mosh;
        var res = $.ajax({
            url: '/ajax_page.php',
            type: 'post',
            data: {what: 'calculator_for', mosh: mosh, cool: cool, hot: hot},
            dataType: 'html',
            complete: function (data) {
                setTimeout("get_boiler(4);", 500);
            },
            cache: false,
            async: false
        }).responseText;

        if (res == '') {
            res = '<span class="err-data">���������  ������</span>';
        }
        $('#for_text').html(res);

    }

    if (a == 5) {
        only_digit2('five_ras');
//		if (document.getElementById('third_vol').value=='') {document.getElementById('third_vol').value='100';}
        only_digit2('five_cool');
//		if (document.getElementById('third_cool').value=='') {document.getElementById('third_cool').value='10';}
        only_digit2('five_hot');
//		if (document.getElementById('third_hot').value=='') {document.getElementById('third_hot').value='55';}


        var ras = document.getElementById('five_ras').value;
        var hot = document.getElementById('five_hot').value;
        var cool = document.getElementById('five_cool').value;


        var res = $.ajax({
            url: '/ajax_page.php',
            type: 'post',
            data: {what: 'calculator_five', ras: ras, cool: cool, hot: hot},
            dataType: 'html',
            complete: function (data) {
                setTimeout("get_boiler(5);", 500);
            },
            cache: false,
            async: false
        }).responseText;

        if (res == '') {
            res = '<span class="err-data">���������  ������</span>';
        }
        $('#five_text').html(res);

    }


    if (a == 6) {
        only_digit2('six_ras');
//		if (document.getElementById('third_vol').value=='') {document.getElementById('third_vol').value='100';}
        only_digit2('six_mosh');
//		if (document.getElementById('third_cool').value=='') {document.getElementById('third_cool').value='10';}
        only_digit2('six_cool');
//		if (document.getElementById('third_hot').value=='') {document.getElementById('third_hot').value='55';}


        var ras = document.getElementById('six_ras').value;
        var mosh = document.getElementById('six_mosh').value;
        var cool = document.getElementById('six_cool').value;

        allparam.mosh = mosh;
        var res = $.ajax({
            url: '/ajax_page.php',
            type: 'post',
            data: {what: 'calculator_six', ras: ras, cool: cool, mosh: mosh},
            dataType: 'html',
            complete: function (data) {
                setTimeout("get_boiler(6);", 500);
            },
            cache: false,
            async: false
        }).responseText;

        if (res == '') {
            res = '<span class="err-data">���������  ������</span>';
        }
        $('#six_text').html(res);

    }
    return;
}

function get_boiler(a) {
    $('.carstr').hide();

    if (a == 5) {
        mosh = $('#five_text').html();

        mosh = only_digit_str(mosh);
        allparam.mosh = mosh;
    }


    $('#carousel_in').html('<div class="carousel" id="main_carousel" style="text-align:center;height:90px;width:980px;overflow:hidden;"><ul id="model_list_carusel"></ul></div>');
    $('#model_list_carusel').empty();

    if (!a || a < 1 || a > 6) {
        a = 1;
    }
    if (a >= 1 && a <= 6) {

        var param = '';
        jQuery('.boiler_type').each(function (el) {

            if (jQuery(this).attr('checked')) {
                if (param != '') {
                    param = param + ':';
                }
                param = param + '' + jQuery(this).attr('value');
            }
        });

        allparam.param = param;
        allparam.calc = current_calc;
        allparam.what = 'calculator_model_list';
        var res = $.ajax({
            url: '/ajax_page.php',
            type: 'post',
            data: allparam,
            dataType: 'html',
            complete: function (data) {
            },
            cache: false,
            async: false
        }).responseText;

        if (res) {
            arr = res.split('|||');

            for (i = 0; i < arr.length; i++) {
                var child = document.createElement("LI");
                tmp = arr[i].split(':::');

                tmphtml = '<div style="width:190px;height:160px;overflow:hidden;z-index:0;margin:0 10px 0 0;border:0px solid blue">' +
                    '<table cellpadding=0 cellspacing=0 width=180 height=100 border="0"><tr>';

                if (tmp[0] != '') {
                    tmphtml += '<td style="padding:0 10px 0 0">' + tmp[0] + '</td>';
                }
                tmphtml += '<td width=100% style="vertical-align:top">' +
                    '<table cellpadding=0 cellspacing=0 height=90px width=100%><tr><td><b>' + tmp[3] + '</b></td></tr>' +
                    '<tr><Td height=10></td></tr>' +
                    '<tr><td><a href="' + tmp[1] + '">' + tmp[4] + '</a></td></tr><tr><Td height=10></td></tr><tr><td style="vertical-align:bottom" height=100%>';
                if (tmp[2] != '') {
                    tmphtml += '<b><div style="display:inline">' + tmp[2] + '&nbsp;</div> ���.</b>';
                }

                tmphtml += '</td></tr></table></td><td><img src="/images/sp.gif" border="0" width=10 height=1></td></tr></table></div>';

                child.innerHTML = tmphtml;
                $('#model_list_carusel').append(child);
            }
            if (arr.length > 5) {
                $('.carstr').show();
            }

            $(".carousel").jCarouselLite({
                btnNext: ".bnext",
                btnPrev: ".bprev",
                visible: 5,
                vertical: false,
                mouseWheel: true,
                //easing: "easeinout",
                scroll: 1,
                circular: false,
                speed: 1000
            });

        }


    }
    $('.model_list_wait').hide();
    return;
}

function show_calc(a, b) {
    $('.model_list_wait').show();
    if (!a || a < 1 || a > 6) {
        a = 1;
    }
    current_calc = a;
//$('#model_list').html('');
    var pos = 0;
    if (document.getElementById(b)) {
        pos = getCaretPos(document.getElementById(b));
    }

    $('.shapka').hide();
    $('.calc_body').hide();
    $('.calc_form').hide();

    if (a == 1) {
        $('#first_form').show();
        $('#first_body').show();
        $('#first_blue').show();
        $('#second_gray').show();
        $('#third_gray').show();

    }
    if (a == 2) {
        $('#second_form').show();
        $('#second_body').show();
        $('#first_gray').show();
        $('#second_blue').show();
        $('#third_gray').show();
    }

    if (a == 3) {
        $('#third_form').show();
        $('#third_body').show();
        $('#first_gray').show();
        $('#second_gray').show();
        $('#third_blue').show();
    }

    if (a == 4) {
        $('#for_form').show();
        $('#for_body').show();
        $('#for_blue').show();
        $('#five_gray').show();
        $('#six_gray').show();
    }

    if (a == 5) {
        $('#five_form').show();
        $('#five_body').show();
        $('#for_gray').show();
        $('#five_blue').show();
        $('#six_gray').show();
    }

    if (a == 6) {
        $('#six_form').show();
        $('#six_body').show();
        $('#for_gray').show();
        $('#five_gray').show();
        $('#six_blue').show();
    }
    check_all_fields(a);
    if (document.getElementById(b)) {
        only_digit2(b);
        setCaretPos(document.getElementById(b), pos);
    }

    return;
}


function check_allmini_fields(a) {

    if (!a || a < 1 || a > 6) {
        a = 1;
    }
    allparam = new Object;

    if (a == 1) {
        only_digit2('first_vol');
        only_digit2('first_mosh');
        only_digit2('first_cool');
//		if (document.getElementById('first_cool').value=='') {document.getElementById('first_cool').value='10';}
        only_digit2('first_hot');
//		if (document.getElementById('first_hot').value=='') {document.getElementById('first_hot').value='55';}

        var tmp;
        var vol = document.getElementById('first_vol').value;
        var mosh = document.getElementById('first_mosh').value;
        var cool = document.getElementById('first_cool').value;
        var hot = document.getElementById('first_hot').value;
        cool = Math.abs(cool);
        hot = Math.abs(hot);

        allparam.vol = vol;
        allparam.mosh = mosh;
        arr = mosh.split(' ');
        mosh = implode('_', arr);

        //	if (cool>hot) {tmp=hot;hot=cool;cool=tmp;}

        //	document.getElementById('first_cool').value=cool;
        //	document.getElementById('first_hot').value=hot;

        var res = $.ajax({
            url: '/ajax_page.php',
            type: 'post',
            data: {what: 'calculator_first', vol: vol, mosh: mosh, cool: cool, hot: hot},
            dataType: 'html',
            complete: function (data) {
            },
            cache: false,
            async: false
        }).responseText;


        if (res == '') {
            res = '<span class="err-data">��������� ������</span>';
        }
        $('#first_text').html(res);
    }

    if (a == 2) {
        only_digit2('second_vol');
        only_digit2('second_cool');
//	if (document.getElementById('second_cool').value=='') {document.getElementById('second_cool').value='10';}
        only_digit2('second_hot');
//	if (document.getElementById('second_hot').value=='') {document.getElementById('second_hot').value='55';}
        only_digit2('second_hc');
//	if (document.getElementById('second_hc').value=='') {document.getElementById('second_hc').value='4';}

        var tmp;
        var vol = document.getElementById('second_vol').value;
        var hc = document.getElementById('second_hc').value;
        var cool = document.getElementById('second_cool').value;
        var hot = document.getElementById('second_hot').value;
        cool = Math.abs(cool);
        hot = Math.abs(hot);
        hc = Math.abs(hc);

//		if (cool>hot) {tmp=hot;hot=cool;cool=tmp;}

//		document.getElementById('second_cool').value=cool;
//		document.getElementById('second_hot').value=hot;	
//		if (hc>hot || hc<cool) {hc=(hot+cool)/2;hc=Math.abs(hc);}
//		document.getElementById('second_hc').value=hc;

        allparam.vol = vol;
        var res = $.ajax({
            url: '/ajax_page.php',
            type: 'post',
            data: {what: 'calculator_second', vol: vol, hc: hc, cool: cool, hot: hot},
            dataType: 'html',
            complete: function (data) {
                setTimeout("get_boiler(2);", 500);
            },
            cache: false,
            async: false
        }).responseText;


        if (res == '') {
            res = '<span class="err-data">���������  ������</span>';
        }
        $('#second_text').html(res);
    }
    if (a == 3) {
        only_digit2('third_vol');
        only_digit2('third_cool');
//		if (document.getElementById('third_cool').value=='') {document.getElementById('third_cool').value='10';}
        only_digit2('third_hot');
//		if (document.getElementById('third_hot').value=='') {document.getElementById('third_hot').value='55';}
        only_digit2('third_price');
//		if (document.getElementById('third_price').value=='') {document.getElementById('third_price').value='4';}

        var vol = document.getElementById('third_vol').value;
        var hot = document.getElementById('third_hot').value;
        var cool = document.getElementById('third_cool').value;
        var price = document.getElementById('third_price').value;
        allparam.vol = vol;
        var res = $.ajax({
            url: '/ajax_page.php',
            type: 'post',
            data: {what: 'calculator_third', vol: vol, price: price, cool: cool, hot: hot},
            dataType: 'html',
            complete: function (data) {
                setTimeout("get_boiler(3);", 500);
            },
            cache: false,
            async: false
        }).responseText;

        if (res == '') {
            res = '<span class="err-data">���������  ������</span>';
        }
        $('#third_text').html(res);

    }


    if (a == 4) {

        only_digit2('for_mosh');
//		if (document.getElementById('third_vol').value=='') {document.getElementById('third_vol').value='100';}
        only_digit2('for_cool');
//		if (document.getElementById('third_cool').value=='') {document.getElementById('third_cool').value='10';}
        only_digit2('for_hot');
//		if (document.getElementById('third_hot').value=='') {document.getElementById('third_hot').value='55';}


        var mosh = document.getElementById('for_mosh').value;
        var hot = document.getElementById('for_hot').value;
        var cool = document.getElementById('for_cool').value;
        arr = mosh.split(' ');
        mosh = implode('_', arr);
        allparam.mosh = mosh;
        var res = $.ajax({
            url: '/ajax_page.php',
            type: 'post',
            data: {what: 'calculator_for', mosh: mosh, cool: cool, hot: hot},
            dataType: 'html',
            complete: function (data) {
                setTimeout("get_boiler(4);", 500);
            },
            cache: false,
            async: false
        }).responseText;

        if (res == '') {
            res = '<span class="err-data">���������  ������</span>';
        }
        $('#for_text').html(res);

    }

    if (a == 5) {

        only_digit2('five_ras');
//		if (document.getElementById('third_vol').value=='') {document.getElementById('third_vol').value='100';}
        only_digit2('five_cool');
//		if (document.getElementById('third_cool').value=='') {document.getElementById('third_cool').value='10';}
        only_digit2('five_hot');
//		if (document.getElementById('third_hot').value=='') {document.getElementById('third_hot').value='55';}


        var ras = document.getElementById('five_ras').value;
        var hot = document.getElementById('five_hot').value;
        var cool = document.getElementById('five_cool').value;


        var res = $.ajax({
            url: '/ajax_page.php',
            type: 'post',
            data: {what: 'calculator_five', ras: ras, cool: cool, hot: hot},
            dataType: 'html',
            complete: function (data) {
                setTimeout("get_boiler(5);", 500);
            },
            cache: false,
            async: false
        }).responseText;

        if (res == '') {
            res = '<span class="err-data">���������  ������</span>';
        }
        $('#five_text').html(res);

    }


    if (a == 6) {

        only_digit2('six_ras');
//		if (document.getElementById('third_vol').value=='') {document.getElementById('third_vol').value='100';}
        only_digit2('six_mosh');
//		if (document.getElementById('third_cool').value=='') {document.getElementById('third_cool').value='10';}
        only_digit2('six_cool');
//		if (document.getElementById('third_hot').value=='') {document.getElementById('third_hot').value='55';}


        var ras = document.getElementById('six_ras').value;
        var mosh = document.getElementById('six_mosh').value;
        var cool = document.getElementById('six_cool').value;
        arr = mosh.split(' ');
        mosh = implode('_', arr);
        allparam.mosh = mosh;
        var res = $.ajax({
            url: '/ajax_page.php',
            type: 'post',
            data: {what: 'calculator_six', ras: ras, cool: cool, mosh: mosh},
            dataType: 'html',
            complete: function (data) {
                setTimeout("get_boiler(6);", 500);
            },
            cache: false,
            async: false
        }).responseText;

        if (res == '') {
            res = '<span class="err-data">���������  ������</span>';
        }
        $('#six_text').html(res);

    }
    return;
}


function show_minicalc(a, b) {
    if (!a || a < 1 || a > 6) {
        a = 1;
    }
    current_calc = a;
//$('#model_list').html('');
    var pos = 0;
    if (document.getElementById(b)) {
        pos = getCaretPos(document.getElementById(b));
    }

    $('.shapka').show();
    $('.body').hide();


    if (a == 1) {
        document.getElementById('first_title').innerHTML = '����� ������� ���� �o ' + document.getElementById('first_hot').value + ' &#8451;';
        $('#body1').show();
        $('#calc1').hide();
    }
    if (a == 2) {
        $('#body2').show();
        $('#calc2').hide();
    }

    if (a == 3) {
        $('#body3').show();
        $('#calc3').hide();
    }

    if (a == 4) {
        $('#body4').show();
        $('#calc4').hide();
    }

    if (a == 5) {
        $('#body5').show();
        $('#calc5').hide();
    }

    if (a == 6) {
        $('#body6').show();
        $('#calc6').hide();
    }
    check_allmini_fields(a);
    if (document.getElementById(b)) {
        only_digit2(b);
        setCaretPos(document.getElementById(b), pos);
    }

    return;
}

function implode(glue, pieces) {


    return ((pieces instanceof Array) ? pieces.join(glue) : pieces);

}
	